<!-- eslint-disable no-tabs -->
<template>
  <div class="backgroup-color-gray">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="container">
          <div class="item item1 txt-black">
            <div class="item1-child">
              <div class="display-1399-block">
                <div v-if="!isLoading && isChecked" class="item1-child1 d-flex flex-column">
                  <b-col>
                    <div class="mb-3 text-3xl text-center">Thông tin thanh toán</div>
                    <b-row class="mb-3">
                      <b-col>
                        <div class="robo-16-500">Tình trạng</div>
                      </b-col>
                      <b-col style="text-align: end;">
                        <div
                          :class="onePayResultParams.vpc_TxnResponseCode === '0' ? 'robo-16-400 text-success lineheight-31' : 'robo-16-400 text-danger lineheight-31'"
                        >{{ onePayResultParams.vpc_TxnResponseCode === '0' ? 'Giao dịch thành công' : 'Giao dịch thất bại'}}</div>
                      </b-col>
                    </b-row>
                    <div
                      v-if="onePayResultParams.vpc_TxnResponseCode != '0'"
                      class="robo-16-500 mb-3 text-danger"
                    >Lý do: {{ responseCode(onePayResultParams.vpc_TxnResponseCode) }}</div>
                    <b-row align-v="center" class="mb-3">
                      <b-col>
                        <div class="robo-16-500">Người đặt lịch</div>
                      </b-col>
                      <b-col style="text-align: end;">
                        <div
                          class="robo-16-400 txt-medium-grey lineheight-31"
                        >{{ apptDetail.person && apptDetail.person.name }}</div>
                      </b-col>
                    </b-row>
                    <b-row align-v="center" class="mb-3">
                      <b-col>
                        <div class="robo-16-500">Giá tiền</div>
                      </b-col>
                      <b-col style="text-align: end;">
                        <div
                          class="robo-16-400 txt-medium-grey lineheight-31"
                        >{{getAmount(apptDetail.invoice && apptDetail.invoice.price, onePayResultParams.vpc_CurrencyCode || 'VNĐ')}}</div>
                      </b-col>
                    </b-row>
                    <b-row align-v="center" class="mb-3">
                      <b-col>
                        <div class="robo-16-500">Số tài khoản</div>
                      </b-col>
                      <b-col style="text-align: end;">
                        <div
                          class="robo-16-400 txt-medium-grey lineheight-31"
                        >{{onePayResultParams.vpc_CardNum}}</div>
                      </b-col>
                    </b-row>
                    <b-row align-v="center" class="mb-3">
                      <b-col>
                        <div class="robo-16-500">Mã giao dịch</div>
                      </b-col>
                      <b-col style="text-align: end; word-break: break-all;">
                        <div
                          class="robo-16-400 txt-medium-grey lineheight-31"
                        >{{onePayResultParams.vpc_MerchTxnRef}}</div>
                      </b-col>
                    </b-row>
                    <b-row align-v="center" class="mb-3">
                      <b-col>
                        <div class="robo-16-500">Nội dung thanh toán</div>
                      </b-col>
                      <b-col style="text-align: end;">
                        <div
                          class="robo-16-400 txt-medium-grey lineheight-31"
                        >{{onePayResultParams.vpc_OrderInfo}}</div>
                      </b-col>
                    </b-row>
                    <div
                      class="robo-16-500 txt-center"
                      v-if="time_out > 0"
                    >Tự động chuyển trang sau {{ time_out }} giây</div>
                    <b-button
                      :to="{path: `/detail-schedule-service/${apptDetail.id}`}"
                      class="mt-auto"
                      variant=" bg-pri text-white"
                      style="width:100%"
                      size="lg"
                    >Xem thông tin lịch khám</b-button>
                    <b-button
                      class="mt-2"
                      v-if="onePayResultParams.vpc_TxnResponseCode !== '0'"
                      @click="rePayment()"
                      variant=" bg-pri text-white"
                      style="width:100%"
                      size="lg"
                    >Thanh toán lại</b-button>
                  </b-col>
                </div>
                <div
                  v-else-if="!isLoading && !isChecked"
                  class="p-4 flex flex-col"
                  style="height: 300px;"
                >
                  <div class="mb-3 text-3xl text-center">Thông tin thanh toán</div>

                  <b-alert show variant="danger" class="my-auto text-center">
                    <div>{{ transactionError }}</div>
                    <div>{{ responseCode(onePayResultParams.vpc_TxnResponseCode) }}</div>
                  </b-alert>

                  <div>
                    <b-button
                      class="mt-2"
                      v-if="onePayResultParams.vpc_TxnResponseCode !== '0'"
                      @click="rePayment()"
                      variant=" bg-pri text-white"
                      style="width:100%"
                      size="lg"
                    >Thanh toán lại</b-button>
                  </div>
                </div>
                <div v-else class="flex" style="height: 300px;">
                  <b-spinner class="m-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BButton } from 'bootstrap-vue'
import appUtils from '../../../utils/appUtils'
// import axios from 'axios'

export default {
  name: 'MedicalRegisterInvoicePublicResult',
  components: {
    BButton,
    BCol,
    BRow
  },
  data () {
    return {
      isLoading: true,
      isChecked: false,
      time_out: 0,
      interval_time_out: null,
      onePayResultParams: null,
      apptDetail: {},
      transactionError: '',
      appUtils
    }
  },
  async created () {
    this.onePayResultParams = { ...this.$route.query }
    await this.getApptDetail(this.$route.query?.appTId)
    await this.validatePaymentResponse()
  },
  methods: {
    async getApptDetail (id) {
      try {
        if (!id) return
        const res = await this.$rf
          .getRequest('AuthRequest')
          .getAppointmentDetail(id)
        this.apptDetail = { ...res.data?.data }
      } catch (error) {
        console.log(error)
      }
    },
    async validatePaymentResponse () {
      if (
        !this.onePayResultParams ||
        Object.keys(this.onePayResultParams).length === 0
      ) {
        this.transactionError = this.$t('Không tìm thấy thông tin giao dịch.')
        this.isChecked = false
        this.isLoading = false
        return
      }

      const params =
        '?' +
        new URLSearchParams(
          this.onePayResultParams || this.$route.query
        ).toString()
      // const { token, appTId, user_id, ...onePayParams } =
      //   this.onePayResultParams || this.$route.query
      const { id, cr_id, person_id, doctor_id } = this.apptDetail
      const payload = {
        appt_id: id,
        cr_id,
        person_id,
        doctor_id
      }

      // this.$router.replace({ query: null })

      try {
        const data = {
          event_type: 2,
          payload_data: JSON.stringify(payload)
          // onepay_params: new URLSearchParams(onePayParams).toString(),
          // web_params: new URLSearchParams({
          //   token,
          //   appTId,
          //   user_id
          // }).toString(),
          // secure_hash: onePayParams?.vpc_SecureHash
        }
        const res = await this.$rf
          .getRequest('AuthRequest')
          .getValidatePaymentResponse(params, data)

        if (!res?.data?.id) {
          this.transactionError = this.$t(
            'medical_register.lbl_transaction_not_successful'
          )
          this.isChecked = false
          this.isLoading = false
          return
        }
        this.isChecked = true
        this.isLoading = false

        this.apptDetail = res.data
        // await this.getApptDetail(id)

        // if (this.$route.query.vpc_TxnResponseCode === '0' && this.isChecked) {
        //   this.setIntervalTimeOut(10)
        // }
      } catch (error) {
        console.log(error)
        this.transactionError = this.$t(
          'medical_register.lbl_transaction_not_successful'
        )
        this.isChecked = false
        this.isLoading = false
      }
    },
    setIntervalTimeOut (seconds) {
      const self = this
      self.time_out = seconds
      clearInterval(self.interval_time_out)
      self.interval_time_out = setInterval(() => {
        if (self.time_out > 0) {
          self.time_out--
        } else {
          clearInterval(self.interval_time_out)
          this.$router.push({
            path: `/detail-schedule-service/${this.apptDetail?.id}`
          })
        }
      }, 1000)
    },
    redirectToDetailSchedule () {},
    async rePayment () {
      const href =
        location.origin +
        `/#/detail-invoice-appointment/${this.$route.params.id}?token=${this.$route.query.token}&appTId=${this.$route.query.appTId}`
      location.replace(href)
    },
    getAmount (amount, currencyUnit) {
      // return amount && this.numberFormat(Number(amount.slice(0, -2)))
      return amount && appUtils.numberFormat(amount, currencyUnit)
    },
    numberFormat (number) {
      return number.toLocaleString(undefined, {
        minimumFractionDigits: 0
      })
    },
    responseCode (code) {
      if (code === '1') {
        return 'Ngân hàng từ chối cấp phép giao dịch.'
      }
      if (code === '2') {
        return 'Ngân hàng phát hành thẻ từ chối cấp phép giao dịch.'
      }
      if (code === '3') {
        return 'Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.'
      }
      if (code === '4') {
        return 'Tháng/Năm hết hạn của thẻ không đúng hoặc thẻ đã hết hạn sử dụng.'
      }
      if (code === '5') {
        return 'Số dư/Hạn mức của thẻ không đủ để thanh toán.'
      }
      if (code === '6') {
        return 'Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.'
      }
      if (code === '7') {
        return 'Lỗi trong quá trình xử lý giao dịch của Ngân hàng.'
      }
      if (code === '8') {
        return 'Ngân hàng phát hành thẻ không hỗ trợ thanh toán trực tuyến.'
      }
      if (code === '9') {
        return 'Tên chủ thẻ/tài khoản không hợp lệ.'
      }
      if (code === '10') {
        return 'Thẻ hết hạn/Thẻ bị khóa.'
      }
      if (code === '11') {
        return 'Thẻ/Tài khoản chưa đăng ký dịch vụ hỗ trợ thanh toán trực tuyến.'
      }
      if (code === '12') {
        return 'Tháng/Năm phát hành hoặc hết hạn của thẻ không hợp lệ.'
      }
      if (code === '13') {
        return 'Giao dịch vượt quá hạn mức thanh toán trực tuyến theo quy định của Ngân hàng.'
      }
      if (code === '14') {
        return 'Số thẻ không hợp lệ. '
      }
      if (code === '21') {
        return 'Số dư tài khoản không đủ để thanh toán.'
      }
      if (code === '22') {
        return 'Thông tin tài khoản không hợp lệ.'
      }
      if (code === '23') {
        return 'Thẻ/Tài khoản bị khóa hoặc chưa được kích hoạt.'
      }
      if (code === '24') {
        return 'Thông tin thẻ/tài khoản không hợp lệ.'
      }
      if (code === '25') {
        return 'Mã xác thực OTP không hợp lệ.'
      }
      if (code === '26') {
        return 'Mã xác thực OTP đã hết hiệu lực.'
      }
      if (code === '98') {
        return 'Xác thực giao dịch bị hủy.'
      }
      if (code === '99') {
        return 'Người dùng hủy giao dịch.'
      }
      if (code === 'B') {
        return 'Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.'
      }
      if (code === 'D') {
        return 'Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.'
      }
      if (code === 'F') {
        return 'Xác thực giao dịch không thành công.'
      }
      if (code === 'U') {
        return 'Xác thực mã CSC không thành công.'
      }
      if (code === 'Z') {
        return 'Giao dịch bị từ chối.'
      }
      if (code === '253') {
        return 'Hết thời hạn nhập thông tin thanh toán.'
      }
      if (code === '') {
        return 'Lỗi không xác định.'
      }
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

.radio-label {
  display: inline-block;
  vertical-align: center;
  margin-right: 3%;
}

input[type="radio"] {
  margin: 0 10px 0 10px;
}

.text-align-right {
  text-align: right;
}

.text-align-right-default {
  text-align: right;
}

.text-align-left-default {
  text-align: left;
}

.container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 5px;
  display: flex;
}

.item {
  margin: 5px;
  color: white;
  width: 50px;
}

.item1 {
  flex-grow: 2;
  flex-basis: 981px;
  /*background-color: #FFFFFF;

border-radius: 5px;

padding: 5px;*/
}

.item1-child {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
}

.item1-child1 {
  display: grid;
  padding: 15px 20px;
}

.item1-child2 {
  height: 5px;
  background-color: #f2f5fa;
}

.item1-child3 {
  background-color: #fff;
  padding: 5px 20px;
}

.item1-child4 {
  padding: 5px 20px;
  background-color: #f2f5fa;
}

.item1-child4-child {
  display: grid;
  grid-template-columns: 50% 50%;
}

.item1-child5 {
  padding: 5px 20px;
}

.item1-child5-child {
  border-bottom: 2px solid #19181a;
}

.item1-child1-500 {
  display: grid;
  padding: 15px 20px;
  grid-template-columns: 50% 50%;
  display: none;
}

.item1-child3-child {
  display: grid;
  grid-template-columns: 50% 50%;
}

.item2 {
  flex-grow: 1;
  flex-basis: 421px;
}

.item2-child {
  padding: 15px 20px;
  background-color: #20409b;
  border-radius: 10px;
}

.item2-child5-child-child-title {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  background-color: #fff;
  width: 100%;
}

.item2-child5-child-child-file {
  margin-top: 0px;
  color: #d2d2d2;
  border-right: 1px solid #d2d2d2;
  line-height: 30px;
  padding-right: 5px;
}

.item2-child5-child-child-str-file {
  margin-left: 10px;
  color: #d2d2d2;
}

.item3 {
  flex-shrink: 2;
}

.robo-25-700 {
  font-weight: 700;
  font-size: 25px;
}

.txt-black {
  color: #fff;
}

.txt-medium-grey {
  color: #6f6e70;
}

.txt-blue-04 {
  color: #20419b;
}

.lineheight-31 {
  line-height: 31.35px;
}

.display-block {
  display: block;
}

.table-bank-tr-frist {
  min-width: 110px;
  display: flex;
  justify-content: top;
}

.min-height-100 {
  min-height: 100vh;
}

.font-weight-normal {
  font-weight: normal;
}

.txt-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
}

.txt-white {
  color: #fff;
}

.bound-img-bank {
  background-color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.icon-copy-white:before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
}

.display-sm-500-none {
  display: block;
}

.display-sm-500-block {
  display: none;
}

.backgroup-color-gray {
  background-color: #e0e9ff;
  height: 100%;
  overflow-x: hidden;
}

.backgroup-color-gray-50 {
  background-color: #f8f7fa;
}

.min-height-98 {
  min-height: 98vh;
}

.bound-big {
  background-color: #ffffff;
  border-radius: 8px;
  border-bottom: 0.5px solid ragb(0, 0, 0, 0.5);
  padding: 15px 20px;
}

.bound-small {
  background-color: #20409b;
  border-radius: 8px;
  min-height: 100%;
}

.btn-status {
  font-size: 12px;
  padding: 5px 5px;
  font-weight: bold;
  border: none;
  background-color: #fff;
}

.mtop-15 {
  margin-top: 15px;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.p-10-10 {
  padding: 10px 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.bb-hr {
  border-bottom: 3px solid black;
}

.txt-black {
  color: #19181a;
}

.p-15-5 {
  padding: 15px 5px;
}

.p-15-8 {
  padding: 15px 8px;
}

.aglin-txt-center {
  display: flex;
  justify-content: center;
}

.mt-12 {
  margin-top: -12px;
}

.wh-20 {
  width: 20px;
  height: 20px;
}

.btn-change-bank {
  border-radius: 50px;
  color: #20409b;
  width: 95%;
  cursor: default !important;
  background-color: #fff;
  padding: 10px 10px;
  text-align: center;
  font-weight: bold;
}

.btn-change-bank-not-select {
  border-radius: 50px;
  color: #20409b;
  width: 95%;
  cursor: default !important;
  background-color: rgb(156, 154, 154);
  padding: 10px 10px;
  text-align: center;
  font-weight: bold;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.bound-bank {
  width: 100%;
  font-weight: normal;
  font-size: 13px;
}

.lh-30 {
  line-height: 30px;
}

.bound-a-copy {
  font-size: 10px;
  color: #fff;
}

.bl-hr {
  border-left: 0.5px solid #d2d2d2;
}

.ml-2px {
  margin-left: 2px;
}

.bound-str-copy {
  background-color: #fff;
  color: #20409b;
  border-radius: 2px;
}

.btn-alert-pay {
  color: #20409b;
  width: 98%;
  font-weight: bold;
  background-color: #fff;
  padding: 10px 10px;
  text-align: center;
  border: none;
  border-radius: 5px;
}

.mt-5px {
  margin-top: -5px;
}

.display-500-none {
  display: none;
}

.display-1399-block {
  display: block;
}

@media (max-width: 1200px) AND (min-width: 1000px) {
  .item1-child1 {
    display: grid;
    grid-template-columns: 20% 25% 35% 30%;
    padding: 15px 20px;
  }
}

@media (max-width: 1000px) AND (min-width: 500px) {
  .container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 5px;
    display: inline-flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  .display-500-none {
    display: block;
  }

  .container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 5px;
    display: inline-flex;
    flex-wrap: wrap;
  }

  .item1-child1 {
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px 20px;
  }

  .item1-child3-child {
    display: grid;
    grid-template-columns: 1fr;
  }

  .text-align-right {
    text-align: left;
  }

  .item1-child1-500 {
    display: grid;
    padding: 15px 20px;
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 1920px) {
  .backgroup-color-gray {
    background-color: #e0e9ff;
    height: 100vh;
  }
}
</style>
